import TooltipCiclogreen from 'components/TooltipCiclogreen'
import { useTranslation } from 'react-i18next'
import { AiOutlineHistory, AiOutlineLineChart } from 'react-icons/ai'
import { BiBarcodeReader, BiShow } from 'react-icons/bi'
import { BsMegaphone, BsPencil, BsPlus } from 'react-icons/bs'
import { IoPodiumOutline, IoTrashOutline } from 'react-icons/io5'
import { SlTrophy } from 'react-icons/sl'

import './Actions.scss'

interface props {
  fx: any
  setup: string[]
  id?: any
  disabled?: string[]
}

export function Actions({ fx, setup, id, disabled }: props) {
  const { t } = useTranslation('tables')
  const handleClickIcon = (e: any) => {
    if (disabled?.indexOf(e.target.parentNode.id)! >= 0) return true
    handleClick(e.target.parentNode.id)
  }
  const total = [
    {
      icon: (
        <BsMegaphone
          id='communicate'
          onClick={(e: any) => handleClickIcon(e)}
          color='#fff'
        />
      ),
      type: 'communicate',
    },
    {
      icon: (
        <BsPlus id='add' size={24} onClick={(e: any) => handleClickIcon(e)} />
      ),
      type: 'add',
    },
    {
      icon: (
        <AiOutlineHistory
          id='history'
          size={24}
          onClick={(e: any) => handleClickIcon(e)}
        />
      ),
      type: 'history',
    },
    {
      icon: (
        <BiBarcodeReader
          size={18}
          id='codes'
          onClick={(e: any) => handleClickIcon(e)}
        />
      ),
      type: 'codes',
    },
    {
      icon: (
        <AiOutlineLineChart
          id='stats'
          onClick={(e: any) => handleClickIcon(e)}
        />
      ),
      type: 'stats',
    },
    {
      icon: (
        <IoPodiumOutline
          id='ranking'
          onClick={(e: any) => handleClickIcon(e)}
        />
      ),
      type: 'ranking',
    },
    {
      icon: <SlTrophy id='winners' onClick={(e: any) => handleClickIcon(e)} />,
      type: 'winners',
    },
    {
      icon: <BsPencil id='edit' onClick={(e: any) => handleClickIcon(e)} />,
      type: 'edit',
    },
    {
      icon: <BiShow id='show' onClick={(e: any) => handleClickIcon(e)} />,
      type: 'show',
    },
    {
      icon: (
        <IoTrashOutline id='remove' onClick={(e: any) => handleClickIcon(e)} />
      ),
      type: 'remove',
    },
  ]
  const handleClick = (botton: any) => {
    fx(botton, id)
  }
  return (
    <div className='actions'>
      {total.map(action => {
        if (setup.indexOf(action.type) >= 0) {
          return (
            <TooltipCiclogreen
              title={t(`actions.${action.type}`)}
              key={action.type}
              disable={disabled?.indexOf(action.type)! >= 0}
            >
              <div
                className={
                  disabled?.indexOf(action.type)! >= 0
                    ? 'actions_action-disabled'
                    : action.type === 'communicate'
                    ? 'actions_primary'
                    : 'actions_action'
                }
                onClick={
                  disabled?.indexOf(action.type)! >= 0
                    ? () => true
                    : (e: any) => handleClick(e.target.id)
                }
                id={action.type}
              >
                {action.icon}
              </div>
            </TooltipCiclogreen>
          )
        }
        return null
      })}
    </div>
  )
}
