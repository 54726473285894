import { Challenge } from '../models/challenge.model';
import { nullToEmpty } from '../utilities';

export const createAdapteChallenge = (challenge: any) => {
  const formattedChallenge: Challenge = {
    name: nullToEmpty(challenge.name),
    target: nullToEmpty(challenge.units_target),
    unit: nullToEmpty(challenge.units),
    start: nullToEmpty(challenge.start),
    end: nullToEmpty(challenge.end),
    visibleOn: nullToEmpty(challenge.visible_on),
    weekdaysOnly: nullToEmpty(challenge.weekdays_only),
    winnerStrategy: nullToEmpty(challenge.winner_strategy),
    numberOfPrizes: nullToEmpty(challenge.number_of_prizes),
    prizes: nullToEmpty(challenge.prizes),
    delimitedArea: nullToEmpty(challenge.delimited_area),
    validActivityTypes: nullToEmpty(challenge.valid_activity_types),
    challengeType: nullToEmpty(challenge.challenge_type),
    description: nullToEmpty(challenge.description),
    image: nullToEmpty(challenge.image),
    location: challenge.location,
    draft: nullToEmpty(challenge.draft),
    status: nullToEmpty(challenge.status),
    subgroups: nullToEmpty(challenge.subgroups),
    uuid: nullToEmpty(challenge.uuid),
    totalActivities: nullToEmpty(challenge.total_activities),
    radius: nullToEmpty(challenge.radius),
    multicompany: nullToEmpty(challenge.multicompany),
    winners: nullToEmpty(challenge.winners),
  };
  return formattedChallenge;
};

export const challengeAdapted = (challenge: any) => {
  const apiFormatChallenge = {
    name: challenge.name,
    units_target: challenge.target,
    units: challenge.unit,
    start: challenge.start,
    end: challenge.end,
    visible_on: challenge.visibleOn,
    weekdays_only: challenge.weekdaysOnly,
    winner_strategy: challenge.winnerStrategy,
    number_of_prizes: challenge.numberOfPrizes,
    prizes: challenge.prizes,
    delimited_area: challenge.delimitedArea,
    valid_activity_types: challenge.validActivityTypes,
    challenge_type: challenge.challengeType,
    description: challenge.description,
    image: challenge.image,
    location: challenge.location,
    draft: challenge.draft,
    status: challenge.status,
    subgroups: challenge.subgroups,
    uuid: challenge.uuid,
    total_activities: challenge.totalActivities,
    radius: challenge.radius,
    multicompany: challenge.multicompany,
    winners: challenge.winners,
  };
  return apiFormatChallenge;
};
