import {
  apiSlice,
  bookingListSlice,
  carpoolingSlice,
  challengeListSlice,
  challengeSelectedSlice,
  communicationKitSlice,
  coordinatesSlice,
  createActivitiesSlice,
  createAdminUserSlice,
  createBookingSlice,
  createChallengeSlice,
  createChipdDataSlice,
  createCompanyConfigSlice,
  createCompanyStructureSlice,
  createNewsSlice,
  createNodeSlice,
  createRewardSlice,
  dashboardSlice,
  newsListSlice,
  prizeSlice,
  rewardsListSlice,
  userSlice,
  yesNoStateSlice,
} from './states';

import { configureStore } from '@reduxjs/toolkit';

export interface AppStore {
  user: any;
  dashboard: any;
  challenges: any;
  challengeSelected: any;
  createChallenge: any;
  createPrize: any;
  createCoordinate: any;
  createNode: any;
  adminUser: any;
  companyConfig: any;
  activitiesList: any;
  companyStructure: any;
  yesNoState: any;
  rewardsList: any;
  createReward: any;
  chipData: any;
  newsList: any;
  createNews: any;
  booking: any;
  bookingList: any;
  carpooling: any;
  communicationKit: any;
  apiState: any;
}

export default configureStore<AppStore>({
  reducer: {
    user: userSlice.reducer,
    dashboard: dashboardSlice.reducer,
    challenges: challengeListSlice.reducer,
    challengeSelected: challengeSelectedSlice.reducer,
    createChallenge: createChallengeSlice.reducer,
    createPrize: prizeSlice.reducer,
    createCoordinate: coordinatesSlice.reducer,
    createNode: createNodeSlice.reducer,
    adminUser: createAdminUserSlice.reducer,
    companyConfig: createCompanyConfigSlice.reducer,
    activitiesList: createActivitiesSlice.reducer,
    companyStructure: createCompanyStructureSlice.reducer,
    yesNoState: yesNoStateSlice.reducer,
    rewardsList: rewardsListSlice.reducer,
    createReward: createRewardSlice.reducer,
    chipData: createChipdDataSlice.reducer,
    newsList: newsListSlice.reducer,
    createNews: createNewsSlice.reducer,
    booking: createBookingSlice.reducer,
    bookingList: bookingListSlice.reducer,
    carpooling: carpoolingSlice.reducer,
    communicationKit: communicationKitSlice.reducer,
    apiState: apiSlice.reducer,
  },
});
