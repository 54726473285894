import React, { createContext, FC, useEffect, useState } from 'react';

interface Iviewport {
  width: number;
  height: number;
  screenWidth: number;
}

interface Props {
  children: React.ReactNode;
}

export const viewportContext = createContext<Iviewport>({
  width: window.innerWidth,
  height: window.innerHeight,
  screenWidth: screen.width,
});

export const ViewportProvider: FC<Props> = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const screenWidth = screen.width;

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return (
    <viewportContext.Provider value={{ width, height, screenWidth }}>
      {children}
    </viewportContext.Provider>
  );
};
