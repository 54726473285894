import { News } from '../models';
import { nullToEmpty } from '../utilities';

export const createAdapteNews = (news: any) => {
  const formattedNews: News = {
    uuid: nullToEmpty(news.uuid),
    name: nullToEmpty(news.title),
    text: nullToEmpty(news.text),
    image: nullToEmpty(news.image),
    createAt: nullToEmpty(news.created_at),
    draft: nullToEmpty(news.draft),
    highlight: nullToEmpty(news.highlight),
    subgroups: nullToEmpty(news.subgroups),
  };
  return formattedNews;
};

export const newsAdapted = (news: any) => {
  const apiFormatNews = {
    uuid: nullToEmpty(news.uuid),
    title: nullToEmpty(news.name),
    text: nullToEmpty(news.text),
    image: nullToEmpty(news.image),
    created_at: nullToEmpty(news.createAt),
    draft: nullToEmpty(news.draft),
    highlight: nullToEmpty(news.highlight),
    subgroups: nullToEmpty(news.subgroups),
  };
  return apiFormatNews;
};
