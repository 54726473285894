import { createSlice } from '@reduxjs/toolkit';
import { emptyDashboard } from 'models/dashboard.model';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: emptyDashboard,
  reducers: {
    createDashboard: (_, action) => action.payload,
    modifyDashboard: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetDashboar: () => emptyDashboard,
  },
});

export const { createDashboard, modifyDashboard, resetDashboar } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
