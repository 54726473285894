import { FormControl, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

import './BasicSelect.scss';

interface props {
  id: string;
  options: any[];
  fx?: any;
  value?: any;
  placeholder?: string;
  handleClick?: Function;
  labelOriginal?: boolean;
  className?: string;
  disabled?: boolean;
}

export function BasicSelect({
  id,
  options,
  fx,
  value,
  placeholder,
  handleClick,
  labelOriginal,
  className,
  disabled,
}: props) {
  const { t } = useTranslation('global');
  const handleChange = (e: any) => {
    const data = e.target.value;
    fx(data, id);
  };
  return (
    <FormControl className={`select_mui`}>
      <Select
        id={id}
        value={value}
        onChange={(e) => handleChange(e)}
        displayEmpty
        className={`${className}`}
        disabled={disabled}
      >
        {placeholder && (
          <MenuItem value=''>
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options &&
          options.map((option: any, idx: number) => {
            const demarkation =
              option.value === 'demarkation' && option.value !== option.label;
            return (
              <MenuItem
                key={idx}
                value={option.value}
                onClick={handleClick ? (e) => handleClick(e) : () => {}}
                id={option.value}
              >
                {labelOriginal
                  ? option.label
                  : demarkation
                  ? `${t('select.demarkation')}: ${option.label}`
                  : t(`select.${option.label}`)}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
