import {createSlice} from "@reduxjs/toolkit";
import {User} from "models";

export const UserEmptyState: User = {
    name: "",
    lastName: "",
    email: "",
    uuid: "",
    img: ""
};

export const userSlice = createSlice({
    name: "user",
    initialState: UserEmptyState,
    reducers: {
        createUser: (_, action) => action.payload,
        modifyUser: (state, action) => (
            {
                ...state,
                ...action.payload
            }
        ),
        resetUser: () => UserEmptyState
    }

});

export const {createUser, modifyUser, resetUser} = userSlice.actions;
export default userSlice.reducer;
