interface DatesCarpooling {
  startdDate: string;
  endDate: string;
}

interface DataCarpooling {
  publishedRoutes: number;
  reservedPlaces: number;
  activeUsers: number;
  totalActivities: number;
  kms: number;
  co2: number;
  savedMoney: number;
  ciclos: number;
}

const empyDatesCarpooling: DatesCarpooling = {
  startdDate: '',
  endDate: '',
};

const empyDataCarpooling: DataCarpooling = {
  publishedRoutes: 0,
  reservedPlaces: 0,
  activeUsers: 0,
  totalActivities: 0,
  kms: 0,
  co2: 0,
  savedMoney: 0,
  ciclos: 0,
};

export interface Carpooling {
  selectedCluster: string;
  datesFilter: string;
  datesTrigger: boolean;
  dates: DatesCarpooling;
  data: DataCarpooling;
  valuesGroupsSelect: any[];
}

export const emptyCarpooling: Carpooling = {
  selectedCluster: '',
  datesFilter: 'allTime',
  datesTrigger: false,
  dates: empyDatesCarpooling,
  data: empyDataCarpooling,
  valuesGroupsSelect: [],
};
