import { challengeListSlice } from './challengesList';

import { createSlice } from '@reduxjs/toolkit';

export const ChallengeSelectedEmpty = {
  selected: '',
  openModalRanking: false,
  openModalEdit: false,
  challenge: {},
  winnerSelected: {},
  rankingFirstPage: {},
};

export const challengeSelectedSlice = createSlice({
  name: 'challengeSelected',
  initialState: ChallengeSelectedEmpty,
  reducers: {
    createChallengeSelected: (_, action) => action.payload,
    modifyChallengeSelected: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetChallengeSelected: () => ChallengeSelectedEmpty,
  },
});

export const {
  createChallengeSelected,
  modifyChallengeSelected,
  resetChallengeSelected,
} = challengeSelectedSlice.actions;

export default challengeListSlice.reducer;
