import Modal from 'components/Atoms/Modal/Modal';
import YouTube, { YouTubeProps } from 'react-youtube';

import './VideoModal.scss';

interface props {
  open: boolean;
  setOpen: any;
  video?: string;
}

export default function VideoModal({ open, setOpen }: props) {
  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    event.target.playVideo();
  };

  const opts: YouTubeProps['opts'] = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  return (
    <Modal title='Video' open={open} setOpen={setOpen}>
      <YouTube videoId='neV3EPgvZ3g' opts={opts} onReady={onPlayerReady} />
    </Modal>
  );
}
