import { User } from './user.model'
export interface Reward {
  uuid?: string
  name: string
  email?: string
  visibleOn?: Date | null
  start?: Date | null
  end?: Date | null
  web?: string
  ciclos: number
  numberOfTotalRewards?: number
  numberOfAviableRewards?: number
  exchangesPerUser?: number
  amountPerYear?: number
  image?: string
  imagePreview?: string
  description?: string
  instructions?: string
  draft?: boolean
  status: string
  claimedCodes?: number
  chipDataGroups?: []
  chipDataSubgroups?: []
}

export const rewardEmpty: Reward = {
  uuid: '',
  name: '',
  visibleOn: null,
  start: null,
  end: null,
  ciclos: 0,
  status: '',
}

export const createRewardEmpty: Reward = {
  name: '',
  visibleOn: null,
  start: null,
  end: null,
  email: '',
  web: '',
  ciclos: 0,
  numberOfTotalRewards: 0,
  exchangesPerUser: 0,
  amountPerYear: 0,
  image: '',
  imagePreview: '',
  description: '',
  instructions: '',
  status: 'draft',
  claimedCodes: 0,
}

export interface EndPointReward {
  name: string
  description: string
  image?: string
  ciclos: number
  contact: string
}

export interface RewardsCodes {
  uuid?: string
  reward?: string
  user?: User
  claimedAt?: Date
  claimCode?: string
}
