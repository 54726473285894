import { FormControlLabel, Radio } from '@mui/material';

import './RadioButton.scss';

interface props {
  value: any;
  label: string;
  disabled?: boolean;
}

export function RadioButton({ value, label, disabled }: props) {
  return (
    <div>
      <FormControlLabel
        value={value}
        control={<Radio disabled={disabled} />}
        label={label}
      />
    </div>
  );
}
