import { createSlice } from '@reduxjs/toolkit';
import { Reward, rewardEmpty } from 'models';

const rewardsListEmpty: Reward[] = [rewardEmpty];

export const rewardsListSlice = createSlice({
  name: 'rewardsList',
  initialState: rewardsListEmpty,
  reducers: {
    createRewardsList: (_, action) => action.payload,
    modifyRewardsList: (state, action) => [...state, action.payload],
    resetRewardsList: () => rewardsListEmpty,
  },
});

export const { createRewardsList, modifyRewardsList, resetRewardsList } =
  rewardsListSlice.actions;
export default rewardsListSlice.reducer;
