import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import moment from 'moment'
import { initReactI18next } from 'react-i18next'

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    // resources: {
    //   es: {
    //     global: globalEs,
    //   },
    //   en: {
    //     global: globalEn,
    //   },
    // },
    // lng: 'es',
    returnNull: false,
    supportedLngs: ['en', 'es', 'eu', 'fr', 'pt', 'it', 'de', 'ca', 'nl'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      format: function (value, format) {
        if (format === 'uppercase') return value.toUpperCase()
        if (value instanceof Date) return moment(value).format(format)
        return value
      },
    },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${new Date().getTime()}`,
    },
  })

export default i18next
