interface DatesDashboard {
  startDate: string;
  endDate: string;
}

export const emptyDates: DatesDashboard = {
  startDate: '',
  endDate: '',
};

export interface Dashboard {
  valuesGroupsSelect: any[];
  valuesGroupsSelect2: any[];
  activitySelected: string;
  filterSelected: string;
  selectedGroup: string;
  datesFilter: string;
  selectedGroup2: string;
  filterComparative: string;
  dates1: DatesDashboard;
  dates2: DatesDashboard;
  dates1Trigger: boolean;
  dates2Trigger: boolean;
  totalsActivities: any;
  dataLineChart: any[];
  totalData: any;
  labelSelectedGroup: string;
  labelSelectedGroup2: string;
  activitySelectedId: string;
  typeComparationDates?: string;
  travelType?: string;
}

export const emptyDashboard: Dashboard = {
  valuesGroupsSelect: [],
  valuesGroupsSelect2: [],
  activitySelected: '',
  filterSelected: 'kms',
  selectedGroup: '',
  datesFilter: 'last30days',
  selectedGroup2: '',
  filterComparative: '',
  dates1: emptyDates,
  dates2: emptyDates,
  dates1Trigger: false,
  dates2Trigger: false,
  totalsActivities: null,
  dataLineChart: [],
  totalData: null,
  labelSelectedGroup: '',
  labelSelectedGroup2: '',
  activitySelectedId: '',
  typeComparationDates: 'months',
  travelType: 'all',
};
