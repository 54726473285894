export interface LinkFaqs {
  section: string;
  sectionId: string;
}

export const faqsUrl: LinkFaqs[] = [
  {
    section: 'statistics',
    sectionId:
      '2PACX-1vRlKUinVwACCt4lgbqj4Kt7zjozd6iZ0s1Oe8Mnq-NJVOQoo0xc6F1JtF8OZQXJAXFdwHnLtA05g7vg',
  },
  {
    section: 'challenges',
    sectionId:
      '2PACX-1vTsFGUflHylt1uVkOrvZq1ge30saIhhJ_AENUg81vajZ5zrFRp-gQFNy5vRDSxVJZ59desI70SVcmyT',
  },
  {
    section: 'rewards',
    sectionId:
      '2PACX-1vTw6X0e6NK4vr8cBqLetqBhQYUD9LwCoZzG2eQwEoLOQJruUrcgaWyaep9uJHP_c0S6cyBMb8wIjHLh',
  },
  {
    section: 'carpooling',
    sectionId:
      '2PACX-1vR6cJrV6VdnFWT_2LO8iIsF0V74vLrPxhCUgGUdUnY_pY8xN2WP_Y55V3W32Uf--uu_iabd_NA8zGJG',
  },
  {
    section: 'news',
    sectionId:
      '2PACX-1vR5I0fjBP8A3UeyqvYZyEGYyJf2AB2TAfRc0TLNEofTbOrbwVxNhkHSLEe9vzpGiuikym1QIKxaui-9',
  },
  {
    section: 'bookings',
    sectionId:
      '2PACX-1vQFAZk4JxW9GMl292-rlTOoPVTYWdn--w1Rie-UbBzzSXV7CKIpOS6bSeKmf_rLf0aP-5mMpBEhSkUk',
  },
  {
    section: 'heatmaps',
    sectionId:
      '2PACX-1vTNw_Lm8svF2lp6PbyeRT24WLh9Ts7PYUsAYnBYqyMHxQ30ZwYFUzf7Bj7sxvRQgkm9fccqLugv68h0',
  },
  {
    section: 'ranking',
    sectionId:
      '2PACX-1vQ68y2hFJdEbUd7y2GjDziJ9cc2ULXgROxtr-YJN_Q0LLmnXqphQOacXClOyFD0LYdRSb0X_Wl9a1lW',
  },
  {
    section: 'polls',
    sectionId:
      '2PACX-1vQhGiR68ym509eE7cDKVFnNlVz1fPAb3gHYMBH4gzImkW59FzFO1v-_jVGPWdU_FFiU2uqxp-ySOaS6',
  },
  {
    section: 'company',
    sectionId:
      '2PACX-1vTtkUXkfNKRdM0b0e9dh8V2AwIcId1m04GGX1oiCENW2Ru06wQe9OnLZ3cX19nJPDG5Y0GN_N1rPhON',
  },
  {
    section: 'settings',
    sectionId:
      '2PACX-1vTyoiJggYzDGWkHGDEx4F8dlFDaTmhQgMDKHuyRSbYNLuWbO0QnFJUbeZ5fz1sgCAB74Qv4xdLgeWvG',
  },
  {
    section: 'communication',
    sectionId:
      '2PACX-1vQVoeqDUSRoNFHeUyKzeRbh93DsApm3tMHSSvtOyd8mFbWQ0D9qZlyrMt6tZoENAMVgFBJxUO_XMFUg',
  },
  {
    section: 'special_challenge',
    sectionId:
      '2PACX-1vQQILw7wTmzI1oVt5PRWU9561y0zLaYBiXz8Uiysl2hcrTmdlDFsaawey__btZYOcHQM8ckQUlZ3r8p',
  },
];
