import { createSlice } from '@reduxjs/toolkit';
import { Prize } from 'models';

export const createPrizeEmpty: Prize = {
  name: '',
  uuid: '',
  image: '',
  description: '',
};

export const prizeSlice = createSlice({
  name: 'create_prize',
  initialState: createPrizeEmpty,
  reducers: {
    createPrize: (_, action) => action.payload,
    modifyPrize: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetPrize: () => createPrizeEmpty,
  },
});

export const { createPrize, modifyPrize, resetPrize } = prizeSlice.actions;
export default prizeSlice.reducer;
