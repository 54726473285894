import { Asset, Booking } from '../models'
import { nullToEmpty } from '../utilities'

export const createAdapteBooking = (booking: any) => {
  const formattedBokking: Booking = {
    uuid: nullToEmpty(booking.uuid),
    name: nullToEmpty(booking.name),
    email: nullToEmpty(booking.contact_email),
    code: nullToEmpty(booking.code),
    start: nullToEmpty(booking.start_date),
    end: nullToEmpty(booking.return_date),
    bookingDate: nullToEmpty(booking.created_at),
    inUse: nullToEmpty(booking.in_use),
    type: nullToEmpty(booking.type),
    daysAllowed: nullToEmpty(booking.max_days_allowed),
    available: nullToEmpty(booking.available),
    description: nullToEmpty(booking.description),
    returnInstructions: nullToEmpty(booking.return_instructions),
    image: nullToEmpty(booking.image),
    draft: nullToEmpty(booking.draft),
    subgroups: nullToEmpty(booking.subgroups),
    futureBookings: nullToEmpty(booking.future_bookings),
    canBeDeleted: nullToEmpty(booking.can_be_deleted),
  }
  return formattedBokking
}

export const createAdapteAsset = (asset: any) => {
  const formattedAsset: Asset = {
    uuid: nullToEmpty(asset.uuid),
    name: nullToEmpty(asset.name),
    description: nullToEmpty(asset.description),
  }
  return formattedAsset
}

export const bookingAdapted = (booking: any) => {
  const apiFormatBooking = {
    uuid: booking.uuid,
    name: booking.name,
    contact_email: booking.email,
    code: booking.code,
    start_date: booking.start,
    return_date: booking.end,
    created_at: booking.bookingDate,
    in_use: booking.inUse,
    type: booking.type,
    max_days_allowed: booking.daysAllowed,
    available: booking.available,
    description: booking.description,
    return_instructions: booking.returnInstructions,
    image: booking.image,
    draft: booking.draft,
    subgroups: booking.subgroups,
    future_bookings: booking.futureBookings,
  }
  return apiFormatBooking
}

export const assetAdapted = (asset: Asset) => {
  const apiFormatAsset = {
    uuid: asset.uuid,
    name: asset.name,
    description: asset.description,
  }
  return apiFormatAsset
}

export const assetAdaptedItem = (asset: Asset) => {
  const selectFormat = {
    value: asset.uuid,
    label: asset.name.split('-')[0],
  }
  return selectFormat
}
