import './SelectorBtn.scss';

interface props {
  label: string;
  fx?: any;
  type: string;
  id: string;
}

export function SelectorBtn({ label, fx, type, id }: props) {
  const handleClick = (e: any) => {
    if (fx) fx(e.target.id);
  };
  let selected = false;
  if (type === id) selected = true;
  return (
    <div
      className={selected ? 'btn selector_selected' : 'btn selector'}
      onClick={(e) => handleClick(e)}
      id={id}
    >
      {label}
    </div>
  );
}
