import { createSlice } from '@reduxjs/toolkit';
import { emptyCarpooling } from 'models';

export const carpoolingSlice = createSlice({
  name: 'carpooling',
  initialState: emptyCarpooling,
  reducers: {
    createCarpooling: (_, action) => action.payload,
    modifyCarpooling: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetCarpooling: () => emptyCarpooling,
  },
});

export const { createCarpooling, modifyCarpooling, resetCarpooling } =
  carpoolingSlice.actions;
export default carpoolingSlice.reducer;
