import { createSlice } from '@reduxjs/toolkit';
import { createRewardEmpty } from 'models';

export const createRewardSlice = createSlice({
  name: 'create_reward',
  initialState: createRewardEmpty,
  reducers: {
    createNewReward: (_, action) => action.payload,
    modifyNewReward: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetNewReward: () => createRewardEmpty,
  },
});
export const { createNewReward, modifyNewReward, resetNewReward } =
  createRewardSlice.actions;
export default createRewardSlice.reducer;
