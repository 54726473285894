import { createSlice } from '@reduxjs/toolkit';
import { createChallengeEmpty } from 'models';

export const createChallengeSlice = createSlice({
  name: 'create_challenge',
  initialState: createChallengeEmpty,
  reducers: {
    createNewChallenge: (_, action) => action.payload,
    modifyNewChallenge: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetNewChallenge: () => createChallengeEmpty,
  },
});
export const { createNewChallenge, modifyNewChallenge, resetNewChallenge } =
  createChallengeSlice.actions;
export default createChallengeSlice.reducer;
