import { Prize } from './prize.model'

export interface Challenge {
  name: string
  target?: number
  unit: string
  start?: Date | null
  end?: Date | null
  visibleOn?: Date | null
  weekdaysOnly: boolean
  winnerStrategy: string
  numberOfPrizes?: number
  prizes?: Prize[]
  delimitedArea: string
  validActivityTypes?: any[]
  challengeType?: string
  description?: string
  image?: string
  imagePreview?: string
  location?: any
  draft: boolean
  status: string
  subGroups?: []
  subgroups?: []
  uuid?: string
  totalActivities: number
  radius?: number
  multicompany?: boolean
  winners?: string[]
}

export const createChallengeEmpty: Challenge = {
  name: '',
  target: undefined,
  unit: 'ciclos',
  start: undefined,
  end: undefined,
  visibleOn: undefined,
  weekdaysOnly: false,
  winnerStrategy: 'random',
  numberOfPrizes: undefined,
  prizes: [],
  delimitedArea: '',
  validActivityTypes: [],
  challengeType: '',
  image: '',
  imagePreview: '',
  draft: true,
  status: '',
  totalActivities: 0,
  multicompany: false,
  winners: [],
}
