import VideoModal from '../VideoModal';

import { useState } from 'react';

import { RoundedBtn } from 'components/Buttons/';
import Helper from 'components/Helper';
import TotalsTable from 'components/Tables/TotalsTable';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import './DrawerLeft.scss';

export default function DrawerLeft() {
  const navigate = useNavigate();
  const location = useLocation();
  let section = location.pathname.split('/')[1];
  if (section === 'faqs') section = '';
  if (section === 'dashboard') section = 'statistics';

  const { t } = useTranslation('layout');
  const [open, setOpen] = useState(false);
  return (
    <div className='drawer_left'>
      <img src='/../logo.png' alt='logo' className='drawer_left-logo' />
      <div className='drawer_left-data'>
        <div className='drawer_left-totals'>
          <TotalsTable />
        </div>
        <div className='drawer_left-helper'>
          <Helper>
            <h3 className='helper-body_title text-big tc color-primary'>
              {t('drawer.help')}
            </h3>
            <p className='text-small text-bold tc color-grey'>
              {t('drawer.text1_help')}
            </p>
            <p className='text-small text-bold tc color-grey'>
              {t('drawer.text2_help')}
            </p>
            <div className='mt'>
              <RoundedBtn
                // fx={() => setOpen(true)}
                fx={() => navigate(`/faqs/${section}`)}
                type='secondary'
                label={t('drawer.cta_help')}
              />
            </div>
          </Helper>
        </div>
      </div>
      <VideoModal setOpen={setOpen} open={open} />
    </div>
  );
}
