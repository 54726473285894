import { useViewport } from '../hooks'
import mediaqueries from '../sass/_mediaqueries.scss'

import { ChartArea } from 'chart.js'
import moment from 'moment'

export function matchActiveTransports(total: any, actives: any) {
  if (total?.length > 0 && actives?.length > 0) {
    const match: any = total.filter(
      (item: any) => actives.indexOf(item.uuid) !== -1
    )
    return match
  } else return []
}

export function locationOptions(list: any, config: any) {
  if (config === 'nolocation') {
    return list.filter((item: any) => item.value !== 'go_to_work')
  }
  return list
}

export function filterEmptyKeys(object: any) {
  for (const key of Object.keys(object)) {
    if (object[key] === '' || object[key]?.length === 0) {
      delete object[key]
    }
  }
  return object
}

export function createGradient(
  ctx: CanvasRenderingContext2D,
  area: ChartArea,
  color: any
) {
  const colorStart = color + '00'
  const colorEnd = color + '66'
  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)
  gradient.addColorStop(0, colorStart)
  gradient.addColorStop(0.9, colorEnd)
  return gradient
}

export function tableLimit(customLimit: number, modal?: boolean) {
  const { screenWidth } = useViewport()
  const breakpointLarge = Number(
    mediaqueries.breakpointDesktopLarge.replace('px', '')
  )
  let limit = customLimit
  if (screenWidth >= breakpointLarge) limit = 14
  if (screenWidth >= breakpointLarge && modal) limit = 13
  return limit
}

export function submitAfterEnter(e: any, fx: Function) {
  if (e.key === 'Enter') fx(e)
}

export function getRepetitions(array: any, key: string) {
  const result: any = []
  const map = new Map()
  for (const item of array) {
    if (!map.has(item[key])) {
      map.set(item[key], true)
      result.push({
        parent: item[key],
        count: 1,
      })
    } else {
      for (const i of result) {
        if (i[key] === item[key]) {
          i.count += 1
        }
      }
    }
  }
  return result
}

export function charactersRemaining(text: string, limit: number) {
  const remaining = limit - text.length
  return remaining
}

export function expirationTrigger(expiration: string) {
  const currentDate = moment()
  const daysDifference = moment(expiration).diff(currentDate, 'days')
  const triggerDays = [45, 40, 35, 30, 25, 20, 15]
  return triggerDays.includes(daysDifference) || daysDifference <= 10
}
