import { createSlice } from '@reduxjs/toolkit';

// interface Location {
//   type: string;
//   coordinates: number[];
// }

interface Nodes {
  name: string;
  //   location?: Location | null;
  location?: any;
  radius?: number;
}

export const createNodeEmpty: Nodes = {
  name: '',
  location: null,
  radius: 1000,
};

export const createNodeSlice = createSlice({
  name: 'node',
  initialState: createNodeEmpty,
  reducers: {
    createNode: (_, action) => action.payload,
    modifyNode: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetNode: () => createNodeEmpty,
  },
});

export const { createNode, modifyNode, resetNode } = createNodeSlice.actions;
export default createNodeSlice.reducer;
