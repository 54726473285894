import { Permission } from '../models';
import { nullToEmpty } from '../utilities';

export const createAdaptePermission = (permission: any) => {
  const formattedPermission: Permission = {
    groupsAdmins: nullToEmpty(permission.groups_admins),
    subgroupsAdmins: nullToEmpty(permission.subgroups_admins),
    uuid: nullToEmpty(permission.uuid),
    user: nullToEmpty(permission.user),
    companyAdmin: permission.company_admin,
  };
  return formattedPermission;
};

export const permissionAdapted = (permission: any) => {
  const apiFormatPermission = {
    groups_admins: permission.groupsAdmins,
    subgroups_admins: permission.subgroupsAdmins,
    user: permission.user,
    company_admin: permission.companyAdmin,
  };
  return apiFormatPermission;
};
