import { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Locale } from 'date-fns';
import * as dateFnsLocales from 'date-fns/locale';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import './BasicDatePicker.scss';
moment.tz.setDefault('UTC');

interface props {
  fx: any;
  name: any;
  type?: string;
  dataLoaded?: any;
  disablePast?: boolean;
  readOnly?: boolean;
  maxDate?: any;
  minDate?: any;
}

interface Locales {
  [key: string]: Locale;
}

export function BasicDatePicker({
  fx,
  name,
  type,
  dataLoaded,
  disablePast,
  readOnly,
  maxDate,
  minDate,
}: props) {
  const { i18n } = useTranslation();
  const [value, setValue] = useState<any | null>(undefined);

  useEffect(() => {
    if (dataLoaded) {
      setValue(
        moment(dataLoaded[name]).locale(i18n.language).format('YYYY-MM-DD')
      );
    } else {
      handleChange(moment().locale(i18n.language).format('YYYY-MM-DD'));
    }
  }, [dataLoaded]);

  const getDateFnsLocale = (): Locale => {
    const locales: Locales = {
      pt: dateFnsLocales.ptBR,
      en: dateFnsLocales.enUS,
      fr: dateFnsLocales.fr,
      es: dateFnsLocales.es,
    };
    return locales[i18n.language];
  };

  const handleChange = (newValue: string | null) => {
    let time = '';
    if (type === 'visibleOn') {
      time = '00:00:01';
    } else if (type === 'start') {
      time = '00:00:02';
    } else {
      time = '23:59:00';
    }
    const dateNotTime = moment(newValue).local(false).format('YYYY-MM-DD');
    const adaptedDate = moment(dateNotTime + ' ' + time)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    setValue(adaptedDate);
    if (fx) {
      if (type) {
        fx(adaptedDate, name);
      } else {
        fx(dateNotTime, name);
      }
    }
  };
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={getDateFnsLocale()}
    >
      <DesktopDatePicker
        className='w-100 p-0'
        inputFormat={i18n.language === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
        value={value}
        onChange={handleChange}
        disablePast={disablePast}
        renderInput={(params) => {
          return <TextField {...params} className='w-100' />;
        }}
        readOnly={readOnly}
        maxDate={maxDate || null}
        {...(minDate && { minDate })}
      />
    </LocalizationProvider>
  );
}
