import LoadingView from './LoadingView';
import useLoading, { LoadingProps } from './useLoading';

import React from 'react';

import { withHook } from 'utilities';

const Loading: React.FC<LoadingProps> = withHook({
  hook: useLoading,
  component: LoadingView,
});
export default Loading;
