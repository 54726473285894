import CircularProgress from '@mui/material/CircularProgress';

import './RoundedBtn.scss';

export function RoundedBtnView({
  type,
  label,
  id,
  loading,
  handleClick,
  handleError,
  disabled,
}: any) {
  return (
    <div
      id={id}
      className={`btn button-rounded ${
        disabled ? 'btn-rounded-disable' : `btn-rounded-${type}`
      }`}
      onClick={
        !loading && type !== 'disable' && !disabled
          ? (e) => handleClick(e)
          : () => handleError()
      }
    >
      <span className='button-rounded_span text-big'>
        {!loading ? label : <CircularProgress color='inherit' size='2rem' />}
      </span>
    </div>
  );
}
