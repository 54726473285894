import colors from '../../../sass/_colors.scss'
import { RoundedBtn } from '../Buttons'
import { H3 } from '../Text/Text'

import Box from '@mui/material/Box'
import ModalMUI from '@mui/material/Modal'
import { ButtonPrimary, ButtonSecondary } from 'constants/buttons'
import { useTranslation } from 'react-i18next'
import { AiOutlineCloseCircle } from 'react-icons/ai'

import './Modal.scss'
export interface ModalProps {
  open: boolean
  setOpen: any
  children: any
  title?: string
  handleConfirm?: Function
  buttonSecondary?: ButtonSecondary
  buttonPrimary?: ButtonPrimary
  disabledPrimaryBtn?: boolean
  errorToast?: any
  onClose?: Function
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  borderRadius: '1.5rem',
  p: 4,
}

export default function Modal({
  open,
  setOpen,
  children,
  title,
  buttonPrimary,
  buttonSecondary,
  handleConfirm,
  disabledPrimaryBtn,
  errorToast,
  onClose,
}: ModalProps) {
  const { t } = useTranslation('global')
  const handleClose = () => {
    setOpen(false)
    if (onClose) onClose()
  }
  return (
    <ModalMUI open={open} onClose={handleClose} disableAutoFocus={true}>
      <Box sx={style} className='box-modal'>
        <>
          <div className='modal-header'>
            <H3>{title}</H3>
            <div
              className='modal-header_close'
              onClick={handleClose}
              id='closeBtn'
            >
              <AiOutlineCloseCircle color={`${colors.colorGreyMedium}`} />
            </div>
          </div>
          <div>{children}</div>
          {buttonPrimary && (
            <div className='modal-actions'>
              {buttonSecondary && (
                <RoundedBtn
                  fx={handleClose}
                  type='secondary'
                  label={t(`btn.${buttonSecondary}`)}
                />
              )}
              {buttonPrimary && (
                <RoundedBtn
                  fx={handleConfirm}
                  type='primary'
                  label={t(`btn.${buttonPrimary}`)}
                  disabled={disabledPrimaryBtn}
                  errorToast={errorToast}
                />
              )}
            </div>
          )}
        </>
      </Box>
    </ModalMUI>
  )
}
