import { createSlice } from '@reduxjs/toolkit';
import { emptyBooking } from 'models';

export const createBookingSlice: any = createSlice({
  name: 'booking',
  initialState: emptyBooking,
  reducers: {
    createBooking: (_, action) => action.payload,
    modifyBooking: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetBooking: () => emptyBooking,
  },
});
export const { createBooking, modifyBooking, resetBooking } =
  createBookingSlice.actions;
export default createBookingSlice.reducer;
