import { createSlice } from '@reduxjs/toolkit';
import { emptyNews } from 'models';

export const createNewsSlice = createSlice({
  name: 'create_news',
  initialState: emptyNews,
  reducers: {
    createNews: (_, action) => action.payload,
    modifyNews: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetNews: () => emptyNews,
  },
});

export const { createNews, modifyNews, resetNews } = createNewsSlice.actions;
export default createNewsSlice.reducer;
