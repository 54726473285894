import { createSlice } from '@reduxjs/toolkit';

export const companyConfigEmpty = {};

export const createCompanyConfigSlice = createSlice({
  name: 'create_config_company',
  initialState: companyConfigEmpty,
  reducers: {
    createCompanyConfig: (_, action) => action.payload,
    modifyCompanyConfig: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});
export const { createCompanyConfig, modifyCompanyConfig } =
  createCompanyConfigSlice.actions;
export default createCompanyConfigSlice.reducer;
