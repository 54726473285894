import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const baseSchema = () => {
  const { t } = useTranslation('errors');
  return Yup.object({
    name: Yup.string()
      .min(2, (error) => t('min_characters', { num: error.min }))
      .max(40, (error) => t('max_characters', { num: error.max }))
      .required(t('required')),
  });
};

export const emailSchema = () => {
  const { t } = useTranslation('errors');
  return Yup.object({
    email: Yup.string().email(t('invalid_email')).required(t('required')),
  });
};
