import DrawerLeft from './components/DrawerLeft';
import Header from './components/Header';

import './BasicLayout.scss';

export default function BasicLayout({ children }: any) {
  return (
    <div className='basic_layout'>
      <div className='basic_layout-left'>
        <DrawerLeft />
      </div>
      <div className='basic_layout-right'>
        <Header />
        <div className='basic_layout-right-body'>{children}</div>
      </div>
    </div>
  );
}
