import { createSlice } from '@reduxjs/toolkit'

export const yesNoEmptyState = {
  openPermissionModal: false,
  openGalleryModal: false,
  openNewPrizeModal: false,
  openLocationModal: false,
  newPermissionAdmin: false,
  openDiplomaModal: false,
  openExpirationModal: false,
  openPrimaryRangeDateModalDashboard: false,
  openPrimaryRangeDateModalCarpooling: false,
  tagGetImages: 'challenge',
  aspectImage: 0,
  typeLocationModal: '',
}

export const yesNoStateSlice = createSlice({
  name: 'yes-no-states',
  initialState: yesNoEmptyState,
  reducers: {
    createYesNoState: (_, action) => action.payload,
    modifyYesNoState: (state, action) => ({ ...state, ...action.payload }),
    resetYesNoState: () => yesNoEmptyState,
  },
})
export const { createYesNoState, modifyYesNoState, resetYesNoState } =
  yesNoStateSlice.actions
export default yesNoStateSlice.reducer
