import { createSlice } from '@reduxjs/toolkit';

export const chipDataEmpty = {
  groups: [],
  subgroups: [],
};

export const createChipdDataSlice = createSlice({
  name: 'chip-data',
  initialState: chipDataEmpty,
  reducers: {
    createChipData: (_, action) => action.payload,
    modifyChipData: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetChipData: () => chipDataEmpty,
  },
});
export const { createChipData, modifyChipData, resetChipData } =
  createChipdDataSlice.actions;
export default createChipdDataSlice.reducer;
