export const setChallengeSelector = (challengeSelector: string) => {
  return window.localStorage.setItem('challengeSelector', challengeSelector);
};

export const getChallengeSelector = () => {
  return window.localStorage.getItem('challengeSelector');
};

export const removeChallengeSelector = () => {
  return window.localStorage.removeItem('challengeSelector');
};

export const setRewardSelector = (rewardSelector: string) => {
  return window.localStorage.setItem('rewardSelector', rewardSelector);
};

export const getRewardSelector = () => {
  return window.localStorage.getItem('rewardSelector');
};

export const removeRewardSelector = () => {
  return window.localStorage.removeItem('rewardSelector');
};

export const setBookingSelector = (bookingSelector: string) => {
  return window.localStorage.setItem('bookingSelector', bookingSelector);
};

export const getBookingSelector = () => {
  return window.localStorage.getItem('bookingSelector');
};

export const removeBookingSelector = () => {
  return window.localStorage.removeItem('bookingSelector');
};

export const setSortField = (setSortField: string) => {
  return window.localStorage.setItem('setSortField', setSortField);
};

export const getSortField = () => {
  return window.localStorage.getItem('setSortField');
};

export const removeSortField = () => {
  return window.localStorage.removeItem('setSortField');
};

export const setSortDirection = (setSortDirection: string) => {
  return window.localStorage.setItem('setSortDirection', setSortDirection);
};

export const getSortDirection = () => {
  return window.localStorage.getItem('setSortDirection');
};

export const removeSortDirection = () => {
  return window.localStorage.removeItem('setSortDirection');
};
