export interface Booking {
  uuid?: string;
  name?: string;
  email: string;
  code?: string;
  start?: Date | null;
  end?: Date | null;
  bookingDate?: Date | null;
  inUse?: boolean;
  type: string;
  daysAllowed: number | string;
  available: boolean;
  description: string;
  returnInstructions: string;
  image: string;
  draft: boolean;
  subgroups?: string[];
  futureBookings?: string[];
  canBeDeleted?: boolean;
}

export interface Asset {
  uuid?: string;
  name: string;
  description: string;
}

export const emptyBooking: Booking = {
  name: '',
  email: '',
  type: '',
  daysAllowed: '',
  available: true,
  description: '',
  returnInstructions: '',
  image: '',
  draft: true,
};

export const emptyAsset: Asset = {
  name: '',
  description: '',
};
