export const dataCompany = {
  location_type: [
    { value: 'nolocation', label: 'no-location' },
    { value: 'company', label: 'company' },
    { value: 'group', label: 'group' },
    { value: 'subGroup', label: 'subgroup' },
  ],
  program_type: [
    { value: 'challenge', label: 'challenges' },
    { value: 'rewards', label: 'rewards' },
  ],
  services: [
    { value: 'movementdetection', label: 'movement_detection' },
    // { value: 'heatmap', label: 'heat_maps' },
    // { value: 'polls', label: 'surveys' },
    { value: 'datadownload', label: 'data_download' },
    { value: 'carpooling', label: 'carpooling' },
    { value: 'reservations', label: 'reservations' },
  ],
};
