import {User} from "../models";
import {nullToEmpty} from "../utilities";

export const createAdaptedUser = (user : any) => {
    const formattedUser: User = {
        name: nullToEmpty(user.first_name),
        lastName: nullToEmpty(user.last_name),
        email: nullToEmpty(user.email),
        uuid: nullToEmpty(user.uuid),
        img: nullToEmpty(user.photo),
        companies: nullToEmpty(user.companies)
    }
    return formattedUser
}
