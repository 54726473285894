import { MouseEventHandler } from 'react';

import { AiOutlineClose } from 'react-icons/ai';
import { BiSearchAlt2 } from 'react-icons/bi';

import './SearchInput.scss';

interface props {
  children: any;
  onClick: MouseEventHandler<SVGElement>;
  showClear?: boolean;
}

export function SearchInput({ children, onClick, showClear }: props) {
  return (
    <div className='input-search' data-testid='search-input'>
      <BiSearchAlt2 data-testid='react-icon' />
      <span className='input-search_data'>{children}</span>
      {showClear && (
        <AiOutlineClose
          data-testid='react-icon_clear'
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        />
      )}
    </div>
  );
}
