import App from './App'
import reportWebVitals from './reportWebVitals'

import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import './services/i18n.service'

import './sass/_index.scss'

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  // <React.StrictMode>
  <Router>
    <App />
  </Router>
  // </React.StrictMode>
)
reportWebVitals()
