import AccountMenu from '../AccountMenu'
// import Notifications from '../Notifications';

import { useEffect, useState } from 'react'

import { createAdaptedUser } from 'adapters'
import appJson from 'appStructure.json'
import { BasicSelect } from 'components/Inputs'
import {
  removeBookingSelector,
  removeChallengeSelector,
  removeRewardSelector,
  removeSortDirection,
  removeSortField,
} from 'data/interfaces/state.interface'
import { useFetchAndLoad } from 'hooks'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { AiOutlineGift, AiOutlineLineChart } from 'react-icons/ai'
import { BiWorld } from 'react-icons/bi'
import { BsBookmarkStar, BsMegaphone } from 'react-icons/bs'
import { FaCar } from 'react-icons/fa'
import { IoPodium, IoTrophyOutline } from 'react-icons/io5'
import { TbListCheck } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  createActivitiesList,
  createCompanyStructure,
  createUser,
  modifyCompanyConfig,
  modifyUser,
  modifyYesNoState,
  resetUser,
} from 'redux/states'
import { AppStore } from 'redux/store'
import {
  getActivities,
  getAssets,
  getCompanyInfo,
  getGroups,
  getUser,
  getUserPermissions,
} from 'services'
import { expirationTrigger } from 'utilities'

import './Header.scss'

const headerJson = appJson.Header

const icons: any[] = [
  AiOutlineLineChart,
  IoTrophyOutline,
  AiOutlineGift,
  FaCar,
  BsMegaphone,
  BsBookmarkStar,
  BiWorld,
  IoPodium,
  TbListCheck,
]

export default function Header() {
  const [t, i18n] = useTranslation('layout')
  const { callEndpoint } = useFetchAndLoad()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userState = useSelector((store: AppStore) => store.user)
  const companyStructure = useSelector(
    (store: AppStore) => store.companyStructure
  )
  const [companyServices, setCompanyServices] = useState<any[]>()
  const [loadingUser, setLoadingUser] = useState(true)
  const logout = () => {
    window.sessionStorage.removeItem('key')
    window.localStorage.removeItem('key')
    dispatch(resetUser())
    navigate('/')
  }
  const getUserData = async () => {
    try {
      const userData = await callEndpoint(getUser())
      dispatch(createUser(createAdaptedUser(userData.data)))
      const companyData = await callEndpoint(getCompanyInfo())
      let locationType = companyData.data.location_type
      if (locationType === 'subgroups') locationType = 'subGroups'
      const services = [].concat(
        companyData.data.program_type,
        companyData.data.services
      )
      const servicesHardcoded: string[] = services.map(service => {
        if (service === 'challenge') return 'challenges'
        if (service === 'reservations') return 'bookings'
        if (service === 'heatmap') return 'heatmaps'
        return service
      })
      servicesHardcoded.push('statistics', 'ranking', 'company', 'settings')
      setCompanyServices(servicesHardcoded)
      dispatch(modifyCompanyConfig(companyData.data))
      const activities = await callEndpoint(getActivities())
      const filterActivities = activities.data.results.filter(
        (activity: any) =>
          companyData.data.activity_types.indexOf(activity.uuid) > -1
      )
      dispatch(createActivitiesList(filterActivities))
      const getPermissions = await callEndpoint(getUserPermissions(0, 500))
      const userPermissions = getPermissions.data.results.filter(
        (user: any) => user.user.uuid === userData.data.uuid
      )[0]
      dispatch(
        modifyUser({
          ...userData.data,
          companyAdmin: userPermissions.company_admin,
          groupsAdmin: userPermissions.groups_admins,
          subgroupsAdmin: userPermissions.subgroups_admins,
        })
      )
      setLoadingUser(false)
      dispatch(
        modifyYesNoState({
          openExpirationModal: expirationTrigger(companyData.data.contract_end),
        })
      )
      const daysDiff = moment(companyData.data.contract_end).diff(
        moment(),
        'days'
      )
      if (daysDiff < 0) {
        window.localStorage.removeItem('key')
        navigate('/')
        toast.error(t('expiration_modal.ended', { ns: 'global' }))
      }
    } catch (error) {}
  }

  const getCompanyStructure = async () => {
    try {
      const getCount = await callEndpoint(getGroups(0, 1))
      const limitGroups = getCount.data.count + 10
      const res = await callEndpoint(getGroups(0, limitGroups))
      dispatch(createCompanyStructure(res.data.results))
    } catch (error) {
      window.localStorage.removeItem('key')
      navigate('/')
    }
  }

  const getAssetsTypes = async () => {
    try {
      const res = await callEndpoint(getAssets())
      dispatch(modifyCompanyConfig({ assetsTypes: res.data.results }))
    } catch (error) {}
  }

  useEffect(() => {
    getUserData()
    getCompanyStructure()
    getAssetsTypes()
  }, [])

  useEffect(() => {
    if (!companyStructure?.length || !userState?.groupsAdmin?.length) {
      return
    }

    const filteredGroups = companyStructure.filter((group: any) =>
      userState.groupsAdmin.includes(group.uuid)
    )

    const allSubgroups = filteredGroups.flatMap(
      (group: any) => group.subgroups || []
    )
    dispatch(
      modifyUser({
        ...userState,
        subgroupsAdmin: [...userState.subgroupsAdmin, ...allSubgroups],
      })
    )
  }, [companyStructure, loadingUser])

  let userNameAvatar = 'Ciclogreen'
  if (userState.name) {
    userNameAvatar = userState?.name.charAt(0)
  }

  const companyData = useSelector((store: AppStore) => store.companyConfig)

  const handleClick = (route: string) => {
    if (route === '/challenges') {
      removeChallengeSelector()
    }
    if (route === '/rewards') {
      removeRewardSelector()
    }
    if (route === '/bookings') {
      removeBookingSelector()
    }
    removeSortField()
    removeSortDirection()
  }

  const links = headerJson.links.map((item, idx) => {
    const Icon = icons[idx]
    return (
      <NavLink
        to={item.route}
        key={item.name}
        onClick={() => handleClick(item.route)}
        className={({ isActive }) =>
          isActive
            ? 'header-left_link-active text-regular text-bold'
            : 'header-left_link'
        }
      >
        <Icon />
        <span className='header-left_link-span text-regular text-bold'>
          {t(`header.${item.name}`)}
        </span>
      </NavLink>
    )
  })

  const filteredLinks = links.filter((item: any) =>
    companyServices?.includes(item.key)
  )

  return (
    <div className='header'>
      <div className='header-left'>{filteredLinks}</div>
      <div className='header-right'>
        {/* <Notifications
            data={[]}
            tooltip={t('header.notifications.tooltip')}
          /> */}
        <AccountMenu
          userName={userNameAvatar}
          userLogo={companyData?.logo}
          logout={logout}
          email={userState?.email}
          companyName={companyData?.name}
          tooltip={t('header.account_menu')}
        />
        <div className='header-right_language'>
          <BasicSelect
            id='language'
            options={headerJson.languages}
            value={i18n.language}
            fx={(value: any) => i18n.changeLanguage(value)}
          />
        </div>
      </div>
    </div>
  )
}
