import { createSlice } from '@reduxjs/toolkit';

export const adminUserEmpty = {
  email: '',
  uuid: '',
  cluster: [],
};

export const createAdminUserSlice = createSlice({
  name: 'create_admin_user',
  initialState: adminUserEmpty,
  reducers: {
    createAdminUser: (_, action) => action.payload,
    modifyAdminUser: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetAdminUser: () => adminUserEmpty,
  },
});
export const { createAdminUser, modifyAdminUser, resetAdminUser } =
  createAdminUserSlice.actions;
export default createAdminUserSlice.reducer;
