import moment from 'moment';

export const useFormatDates = (datesFilter: string, filterSelected: string) => {
  const weekAgo = moment().subtract(7, 'days').format('YYYY-MM-DD').toString();
  const monthAgo = moment()
    .subtract(30, 'days')
    .format('YYYY-MM-DD')
    .toString();
  const yearAgo = moment().subtract(1, 'years').format('YYYY-MM-DD').toString();
  const filterSelectedFormated = () => {
    if (filterSelected === 'ciclos_total') return (filterSelected = 'ciclos');
    return filterSelected;
  };
  let date = '';
  let groupByMonths = '';
  if (datesFilter === 'lastWeek') {
    date = weekAgo;
    groupByMonths = 'day';
  }
  if (datesFilter === 'last30days') {
    date = monthAgo;
    groupByMonths = 'day';
  }
  if (datesFilter === 'lastYear') {
    date = yearAgo;
    groupByMonths = 'month';
  }
  if (datesFilter === 'custom') {
    groupByMonths = 'day';
  }
  return { filterSelectedFormated, date, groupByMonths };
};
