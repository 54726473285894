import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

import './BasicSwitch.scss';

interface props {
  stateSwitch: boolean;
  setStateSwitch: any;
  leftLabel?: string;
  rightLabel?: string;
  fx?: any;
}

export function BasicSwitch({
  stateSwitch,
  setStateSwitch,
  leftLabel,
  rightLabel,
  fx,
}: props) {
  const handleChange = () => {
    setStateSwitch(!stateSwitch);
    if (fx) fx();
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#0ab496',
      '&:hover': {
        backgroundColor: alpha('#0ab496', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#0ab496',
    },
  }));

  return (
    <div className='basic-switch'>
      <span className='basic-switch_label'>{leftLabel}</span>
      <GreenSwitch
        onChange={handleChange}
        checked={stateSwitch}
        role='switch'
      />
      <span className='basic-switch_label'>{rightLabel}</span>
    </div>
  );
}
