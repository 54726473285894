import CircularProgress from '@mui/material/CircularProgress';

import './RoundedBtn.scss';

interface props {
  fx: any;
  type: string;
  label: string;
  id?: any;
  loading?: boolean;
  errorToast?: any;
}

export function RoundedBtn({
  fx,
  type,
  label,
  id,
  loading,
  errorToast,
}: props) {
  const handleClick = (e: any) => {
    if (e) {
      fx(e);
    } else {
      fx();
    }
  };
  const handleError = () => {
    if (errorToast) errorToast();
    return true;
  };
  return (
    <div
      id={id}
      className={`btn button-rounded btn-rounded-${type}`}
      onClick={
        !loading && type !== 'disable'
          ? (e) => handleClick(e)
          : () => handleError()
      }
    >
      <span className='button-rounded_span text-big'>
        {!loading ? label : <CircularProgress color='inherit' size='2rem' />}
      </span>
    </div>
  );
}
