import {createSlice} from "@reduxjs/toolkit";

export const ChallengeListEmpty: any[] = [];

export const challengeListSlice = createSlice({
    name: "challengeList",
    initialState: ChallengeListEmpty,
    reducers: {
        createChallengesList: (_, action) => action.payload,
        modifyChallengesList: (state, action) => [
            ...state,
            action.payload
        ],
        resetChallengesList: () => ChallengeListEmpty
    }
})

export const {createChallengesList, modifyChallengesList, resetChallengesList} = challengeListSlice.actions;
export default challengeListSlice.reducer
