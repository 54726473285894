export interface News {
  uuid: string;
  name: string;
  text: string;
  image: string;
  createAt: Date | null;
  draft: boolean;
  highlight: boolean;
  subgroups: any[];
}

export const emptyNews: News = {
  uuid: '',
  name: '',
  text: '',
  image: '',
  createAt: null,
  draft: true,
  highlight: false,
  subgroups: [],
};
