import { createSlice } from '@reduxjs/toolkit';

export const emptyApiState = {
  reportLoadingProgress: 0,
};

export const apiSlice = createSlice({
  name: 'api',
  initialState: emptyApiState,
  reducers: {
    createApiState: (_, action) => action.payload,
    modifyApiState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetApiState: () => emptyApiState,
  },
});

export const { createApiState, modifyApiState, resetApiState } =
  apiSlice.actions;
export default apiSlice.reducer;
