import { createSlice } from '@reduxjs/toolkit';

export const emptyBookingsList: any[] = [];

export const bookingListSlice = createSlice({
  name: 'bookingList',
  initialState: emptyBookingsList,
  reducers: {
    createBookingsList: (_, action) => action.payload,
    modifyBookingList: (state, action) => [...state, ...action.payload],
    resetBookingList: () => emptyBookingsList,
  },
});
export const { createBookingsList, modifyBookingList, resetBookingList } =
  bookingListSlice.actions;
export default bookingListSlice.reducer;
