import { createSlice } from '@reduxjs/toolkit';

export const createCompanyStructureEmpty = [];

export const createCompanyStructureSlice = createSlice({
  name: 'company-structure',
  initialState: createCompanyStructureEmpty,
  reducers: {
    createCompanyStructure: (_, action) => action.payload,
    modifyCompanyStructure: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetCompanyStructure: () => createCompanyStructureEmpty,
  },
});
export const {
  createCompanyStructure,
  modifyCompanyStructure,
  resetCompanyStructure,
} = createCompanyStructureSlice.actions;
export default createCompanyStructureSlice.reducer;
