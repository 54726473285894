import { ImInfo } from 'react-icons/im';

import './Helper.scss';

interface props {
  children: any;
  classHelper?: string;
}

export default function Helper({ children, classHelper }: props) {
  return (
    <div className={'helper' + ' ' + classHelper}>
      <div className='helper-icon'>
        <div className='helper-icon-background'>
          <ImInfo size={30} />
        </div>
      </div>
      <div className='helper-body'>{children}</div>
    </div>
  );
}
