import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import './NavigationBtn.scss';

interface props {
  next: boolean;
  handleClickNextBack: any;
}

export function NavigationBtn({ next, handleClickNextBack }: props) {
  const handleClick = () => {
    handleClickNextBack(next);
  };
  return (
    <div className='navigation_btn' onClick={handleClick}>
      {next ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />}
    </div>
  );
}
