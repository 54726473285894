import axios from 'axios'

export const baseURL = process.env.REACT_APP_BASE_URL

const jwtInterceptor = axios.create({
  baseURL,
  withCredentials: true,
})

let isRefreshing = false
let failedQueue: any[] = []

const processQueue = (error: any, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const removeS3PresignedParams = (url: string) => {
  const urlObj = new URL(url)
  const queryParams = urlObj.searchParams
  queryParams.delete('Key-Pair-Id')
  queryParams.delete('Expires')
  queryParams.delete('Signature')
  const cleanedUrl = `${urlObj.origin}${urlObj.pathname}${
    queryParams.toString() ? `?${queryParams.toString()}` : ''
  }`
  return cleanedUrl
}

const cleanBodyUrl = (body: any) => {
  console.log('###Ejecuto cleanBodyUrl###')
  console.log('Body:', JSON.stringify(body, null, 2))
  if (typeof body === 'object') {
    const awsPresignedRegex = /(\?|&)Expires=\d+/
    console.log('Original Body:', JSON.stringify(body, null, 2)) // Logging original body for debugging

    for (const key in body) {
      if (typeof body[key] === 'string' && awsPresignedRegex.test(body[key])) {
        console.log(`Cleaning URL in key: ${key}`) // Log specific key being sanitized
        body[key] = removeS3PresignedParams(body[key])
      }
    }

    console.log('Sanitized Body:', JSON.stringify(body, null, 2)) // Logging sanitized body for debugging
  }
  return body
}

jwtInterceptor.interceptors.request.use(
  config => {
    config.headers!['X-Requested-With'] = 'XMLHttpRequest'
    const token = localStorage.getItem('key')
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => Promise.reject(error)
)
// NUEVO
jwtInterceptor.interceptors.request.use(function (request) {
  request.data = cleanBodyUrl(request.data)
  return request
})
// NUEVO

const isRefreshTokenUrl = (url: string) => {
  return url.includes('/api/auth/token/refresh')
}

jwtInterceptor.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const originalRequest = error.config
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !isRefreshing
    ) {
      if (isRefreshTokenUrl(originalRequest.url)) {
        handleLogout()
        return Promise.reject(error)
      }

      originalRequest._retry = true
      isRefreshing = true

      try {
        const refreshResponse = await axios
          .post(
            `${baseURL}/api/auth/token/refresh/`,
            {},
            {
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
              },
            }
          )
          .then(response => {
            if (response.status !== 200) {
              handleLogout()
              return Promise.reject(response)
            }
            return response
          })
          .catch(err => {
            console.error('Error en la solicitud de refresco', err)
            handleLogout()
            return Promise.reject(err)
          })

        const newToken = refreshResponse.data.access
        localStorage.setItem('key', newToken)

        jwtInterceptor.defaults.headers.common.Authorization = `Bearer ${newToken}`
        originalRequest.headers.Authorization = `Bearer ${newToken}`

        processQueue(null, newToken)
        return jwtInterceptor({
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: `Bearer ${newToken}`,
          },
          withCredentials: true,
          method: originalRequest.method,
          url: originalRequest.url,
          data: originalRequest.data,
        })
      } catch (refreshError) {
        processQueue(refreshError, null)
        handleLogout()
        return Promise.reject(refreshError)
      } finally {
        isRefreshing = false
      }
    }

    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject })
      })
        .then(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`
          return jwtInterceptor({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
            method: originalRequest.method,
            url: originalRequest.url,
            data: originalRequest.data,
          })
        })
        .catch(err => {
          return Promise.reject(err)
        })
    }

    return Promise.reject(error)
  }
)

function handleLogout() {
  localStorage.removeItem('key')
  sessionStorage.removeItem('key')
  console.error('Sesión expirada. Redirigiendo a la página de inicio.')
  window.location.href = '/'
}

export default jwtInterceptor
