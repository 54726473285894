import moment from 'moment';

export function samplingMonthWithData(object: any, fromDate: any) {
  const quantityDaysPerMonth = moment(fromDate).daysInMonth();
  const monthFromDate = moment(fromDate).month() + 1;
  const yearFromDate = moment(fromDate).year();
  const totalArray = [...Array(quantityDaysPerMonth + 1).keys()].slice(1);
  const formatedArray = totalArray.map((day, idx) => {
    return {
      active_users: 0,
      ciclos: 0,
      co2: 0,
      index: idx,
      kms: 0,
      label: moment(`${yearFromDate}-${monthFromDate}-${day}`).format('L'),
      money_saving: 0,
      total_activities: 0,
    };
  });
  const formatedObject = object.map((date: any) => {
    return {
      ...date,
      index: Number(date.label.split('-')[2]),
      label: moment(date.label).format('L'),
    };
  });
  const objectInArray = formatedArray.map((itemArray: any) => {
    const indexMatch = formatedObject.filter(
      (item: any) => item.index === itemArray.index
    );
    if (indexMatch.length > 0) return indexMatch[0];
    return itemArray;
  });
  return objectInArray;
}

export function samplingAnyDates(object: any, fromDate: any, untilDate: any) {
  const dates = [];
  const a = moment(fromDate);
  const b = moment(untilDate);
  const difference = b.diff(a, 'days') + 1;
  const differenceMonths = b.diff(a, 'months') + 1;
  let limitFor = difference;
  let month = false;
  if (difference > 31) {
    limitFor = differenceMonths;
    month = true;
  }
  for (let index = 0; index < limitFor; index++) {
    const element = moment(fromDate)
      .add(index, month ? 'month' : 'day')
      .format(month ? 'YYYY-MM' : 'YYYY-MM-DD');
    dates.push(element);
  }
  const formatedArray = dates.map((date, idx) => {
    return {
      active_users: 0,
      ciclos: 0,
      co2: 0,
      index: idx,
      kms: 0,
      label: date,
      money_saving: 0,
      total_activities: 0,
    };
  });
  const formatedObject = object.map((date: any, idx: number) => {
    return {
      ...date,
      index: idx,
      label: moment(date.label).format(month ? 'YYYY-MM' : 'YYYY-MM-DD'),
    };
  });
  const objectInArray = formatedArray
    .map((itemArray: any) => {
      const indexMatch = formatedObject.filter(
        (item: any) => item.label === itemArray.label
      );
      if (indexMatch.length > 0) return indexMatch[0];
      return itemArray;
    })
    .map((date: any, idx: number) => {
      return { ...date, index: idx };
    });
  return objectInArray;
}
