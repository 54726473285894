import colors from '../../sass/_colors.scss';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import './TooltipCiclogreen.scss';

interface props {
  children: any;
  title: string;
  disable: boolean;
}

export default function TooltipCiclogreen({ children, title, disable }: props) {
  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 600 }}
      followCursor
      disableTouchListener
      disableFocusListener
      disableInteractive
      disableHoverListener={disable}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${colors.colorPrimary}`,
      color: '#FFFFFF',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  return <CustomTooltip title={title}>{children}</CustomTooltip>;
}
