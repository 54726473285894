import { Reward, RewardsCodes } from '../models/reward.model';
import { nullToEmpty } from '../utilities';
import { createAdaptedUser } from './user.adapter';

export const createAdapteReward = (reward: any) => {
  const formattedReward: Reward = {
    uuid: nullToEmpty(reward.uuid),
    name: nullToEmpty(reward.name),
    email: nullToEmpty(reward.contact_email),
    visibleOn: nullToEmpty(reward.visible_from),
    start: nullToEmpty(reward.active_from),
    end: nullToEmpty(reward.valid_until),
    web: nullToEmpty(reward.web_site),
    ciclos: nullToEmpty(reward.ciclos),
    numberOfTotalRewards: nullToEmpty(reward.total_posible_claims),
    numberOfAviableRewards: nullToEmpty(reward.unclaimed),
    exchangesPerUser: nullToEmpty(reward.posible_claims_per_users),
    amountPerYear: nullToEmpty(reward.monetary_value),
    image: nullToEmpty(reward.image),
    description: nullToEmpty(reward.description),
    instructions: nullToEmpty(reward.claim_instructions),
    draft: nullToEmpty(reward.draft),
    status: nullToEmpty(reward.status),
    claimedCodes: nullToEmpty(reward.total_posible_claims),
  };
  return formattedReward;
};

export const adaptedReward = (reward: any) => {
  const adapted = {
    name: nullToEmpty(reward.name),
    description: nullToEmpty(reward.description),
    image: nullToEmpty(reward.image),
    ciclos: nullToEmpty(reward.ciclos),
    contact_email: nullToEmpty(reward.email),
    visible_from: nullToEmpty(reward.visibleOn),
    active_from: nullToEmpty(reward.start),
    valid_until: nullToEmpty(reward.end),
    total_posible_claims: nullToEmpty(reward.numberOfTotalRewards),
    monetary_value: nullToEmpty(reward.amountPerYear),
    posible_claims_per_users: nullToEmpty(reward.exchangesPerUser),
    web_site: nullToEmpty(reward.web),
    claim_instructions: nullToEmpty(reward.instructions),
    draft: nullToEmpty(reward.draft),
  };
  return adapted;
};

export const CreateAdapteRewardsCode = (rewardCode: any) => {
  const formattedRewardsCode: RewardsCodes = {
    uuid: nullToEmpty(rewardCode.uuid),
    reward: nullToEmpty(rewardCode.reward),
    user: nullToEmpty(createAdaptedUser(rewardCode.user)),
    claimedAt: nullToEmpty(rewardCode.claimed_at),
    claimCode: nullToEmpty(rewardCode.claim_code),
  };
  return formattedRewardsCode;
};

export const adaptedRewardsCode = (rewardCode: any) => {
  const adapted = {
    uuid: nullToEmpty(rewardCode.uuid),
    reward: nullToEmpty(rewardCode.reward),
    user: nullToEmpty(createAdaptedUser(rewardCode.user)),
    claimed_at: nullToEmpty(rewardCode.claimedAt),
    claim_code: nullToEmpty(rewardCode.claimCode),
  };
  return adapted;
};
