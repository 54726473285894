import './BasicLabelInput.scss';

interface props {
  label: {
    primary?: string;
    secondary?: string;
    comments?: string;
    special?: string;
    primaryDots?: boolean;
    secondaryDots?: boolean;
  };
  className?: string;
}

export default function BasicLabelInput({ label, className }: props) {
  const { primary, secondary, comments, special, primaryDots, secondaryDots } =
    label;
  return (
    <div
      className={
        className ? `basic-label-input  ${className}` : 'basic-label-input'
      }
    >
      <span className='basic-label-input__primary'>{primary}</span>
      {primaryDots && <span className='basic-label-input__dots'>:</span>}
      <span className='basic-label-input__comments'>{comments}</span>
      <span className='basic-label-input__special'>{special}</span>
      <span className='basic-label-input__secondary'>{secondary}</span>
      {secondaryDots && <span className='basic-label-input__dots'>:</span>}
    </div>
  );
}
