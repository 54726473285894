import { createSlice } from '@reduxjs/toolkit';

interface coords {
  lat: number;
  lng: number;
}

export const CoordinatesEmptyState: coords = {
  lat: 40.4167754,
  lng: -3.7037902,
};

export const coordinatesSlice = createSlice({
  name: 'coordinates',
  initialState: CoordinatesEmptyState,
  reducers: {
    createCoordinate: (_, action) => action.payload,
    modifyCoordinate: (state, action) => ({ ...state, ...action.payload }),
    resetCoordinate: () => CoordinatesEmptyState,
  },
});

export const { createCoordinate, modifyCoordinate, resetCoordinate } =
  coordinatesSlice.actions;
export default coordinatesSlice.reducer;
