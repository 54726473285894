import { RoundedBtnView } from './RoundedBtnView';
import useRoundedBtn, { RoundedBtnProps } from './useRoundedBtn';

import React from 'react';

import { withHook } from 'utilities';

const RoundedBtn: React.FC<RoundedBtnProps> = withHook({
  hook: useRoundedBtn,
  component: RoundedBtnView,
});
export default RoundedBtn;
