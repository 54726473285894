import { createSlice } from '@reduxjs/toolkit';

export const newsListEmpty: any[] = [];

export const newsListSlice = createSlice({
  name: 'news',
  initialState: newsListEmpty,
  reducers: {
    createNewsList: (_, action) => action.payload,
    modifyNewsList: (state, action) => [...state, ...action.payload],
    resetNewsList: () => newsListEmpty,
  },
});

export const { createNewsList, modifyNewsList, resetNewsList } =
  newsListSlice.actions;
export default newsListSlice.reducer;
