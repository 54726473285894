import { createSlice } from '@reduxjs/toolkit';

export const communicationKitEmpty = {
  filterChallenge: 'active',
};

export const communicationKitSlice = createSlice({
  name: 'communication-kit',
  initialState: communicationKitEmpty,
  reducers: {
    createCommunicationKit: (_, action) => action.payload,
    modifyCommunicationKit: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetCommunicationKit: () => communicationKitEmpty,
  },
});
export const {
  createCommunicationKit,
  modifyCommunicationKit,
  resetCommunicationKit,
} = communicationKitSlice.actions;
export default communicationKitSlice.reducer;
