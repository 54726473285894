import CiclogreenIcon from 'components/Atoms/Icons/CiclogreenIcon';
import './Loading.scss';

export default function LoadingView({ message, t }: any) {
  return (
    <div className='loading'>
      <div className='loading-elements'>
        <div className='loading-elements-animation'>
          {/* <img
            src='/../favicon.png'
            alt='favicon'
            className='loading-elements-animation_png'
          /> */}
          <CiclogreenIcon width={130} height={130} />
          <div className='loading-elements-animation_spinner-box'>
            <div className='loading-elements-animation_circle-border'>
              <div className='loading-elements-animation_circle-core'></div>
            </div>
          </div>
        </div>
        <h4>
          {t('loading_message', {
            message,
          })}
        </h4>
      </div>
    </div>
  );
}
