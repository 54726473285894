import { routes } from './routes';

import React from 'react';

// import CircularProgress from '@mui/material/CircularProgress';
import DemoLabel from 'components/Labels/DemoLabel';
import Loading from 'pages/Loading';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

export default function Navigation() {
  const { t } = useTranslation('global');
  return (
    <>
      <Routes>
        {routes.map((route: any, idx: number) => {
          return (
            <Route
              key={idx}
              path={route.path}
              element={
                <>
                  {process.env.REACT_APP_ENV_NAME !== 'main' && <DemoLabel />}
                  <route.layout>
                    <React.Suspense
                      fallback={
                        // <CircularProgress color='success' />
                        <Loading message={t('loading_view')} />
                      }
                    >
                      <route.page />
                    </React.Suspense>
                  </route.layout>
                </>
              }
            />
          );
        })}
      </Routes>
    </>
  );
}
