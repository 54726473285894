import { createSlice } from '@reduxjs/toolkit';

export const activitiesEmpty = [];

export const createActivitiesSlice = createSlice({
  name: 'activities',
  initialState: activitiesEmpty,
  reducers: {
    createActivitiesList: (_, action) => action.payload,
  },
});
export const { createActivitiesList } = createActivitiesSlice.actions;
export default createActivitiesSlice.reducer;
