import {
  Checkbox,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './BasicMultipleSelect.scss';

interface props {
  id: string;
  options: any[];
  selected?: any;
  setSelected?: any;
  allMessage: string;
}

export function BasicMultipleSelect({
  id,
  options,
  selected,
  setSelected,
  allMessage,
}: props) {
  const { t } = useTranslation('global');
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = (e: any) => {
    const { value } = e.target;
    if (value.includes('all')) {
      if (selected.length === options.length) {
        setSelected([]);
      } else {
        const allSelectedOptions = options.map((option) => option.label);
        setSelected(allSelectedOptions);
      }
      return;
    }
    setSelected(value);

    if (value.length === options.length - 1) {
      const selectedSubgroups = options
        .filter((option) => value.includes(option.label))
        .flatMap((option) => option.subgroups)
        .map((subgroup) => subgroup.label);
      setSelected(selectedSubgroups);
    }
  };

  return (
    <FormControl className='select_mui' sx={{ width: 250 }}>
      <Select
        id={id}
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
      >
        <MenuItem value='all'>
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText primary={t(`select.${allMessage}`)} />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.label}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option.label) > -1} />
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
