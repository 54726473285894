import BasicLabelInput from 'components/Labels/BasicLabelInput';

import './BasicInput.scss';

export interface LabelProps {
  primary?: string;
  secondary?: string;
  primaryDots?: boolean;
  secondaryDots?: boolean;
  special?: string;
  comments?: string;
}

export interface BasicInputProps {
  children?: any;
  label?: LabelProps;
  className?: string;
  classNameInput?: string;
  messageError?: string;
  error?: boolean;
  disabled?: boolean;
  message?: string;
}

export function BasicInput({
  children,
  label,
  className,
  classNameInput,
  error,
  messageError,
  disabled,
  message,
}: BasicInputProps) {
  return (
    <>
      <div
        className={
          `${error ? 'basic-input-error' : 'basic-input'} ` +
          (classNameInput || '')
        }
        data-testid='basic-input'
      >
        <div className='basic-input__label'>
          {label && <BasicLabelInput label={label} className={className} />}
        </div>
        <div
          className={
            `${error ? 'basic-input-error__box ' : 'basic-input__box '}` +
            (classNameInput || '') +
            (disabled ? ' input_disabled' : '')
          }
        >
          {children}
        </div>
        {error && (
          <span className='basic-input-error__error'>
            {error ? messageError : ' '}
          </span>
        )}
        {message && <span className='basic-input__message'>{message}</span>}
      </div>
    </>
  );
}
