export function matchArray(key: string, a: any, b: any) {
  const data = a[key];
  const data2 = b[key];
  if (data && data2) {
    const matching = data.map((type: any) => {
      if (data2.includes(`${type.uuid ? type.uuid : type.value}`))
        return {
          label: type.slug ? type.slug : type.label,
          active: true,
        };
      return {
        label: type.slug ? type.slug : type.label,
        active: false,
      };
    });
    return matching;
  } else {
    return [];
  }
}
export function swap(arr: any[], idx1: number, idx2: number) {
  const aux = arr[idx1];
  arr[idx1] = arr[idx2];
  arr[idx2] = aux;
  return aux;
}
