export interface RoundedBtnProps {
  fx: any;
  type: string;
  label: string;
  id?: any;
  loading?: boolean;
  errorToast?: any;
  disabled?: boolean;
}

export default function useRoundedBtn({
  fx,
  type,
  label,
  id,
  loading,
  errorToast,
  disabled,
}: RoundedBtnProps) {
  const handleClick = (e: any) => {
    if (e) {
      fx(e);
    } else {
      fx();
    }
  };
  const handleError = () => {
    if (errorToast) errorToast();
    return true;
  };
  return {
    type,
    label,
    id,
    loading,
    handleClick,
    handleError,
    disabled,
  };
}
